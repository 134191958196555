<template>
  <div class="product-page px-6">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto py-1" outlined>
          <v-card-text class="py-0">
            <v-col cols="12" class="subtitle-1 font-weight-bold py-1">
              Want to save time and lower your fulfillment cost?
            </v-col>
            <v-col cols="12">
              <v-btn outlined color="primary" class="mr-3">
                Enable auto fulfill with Dcomcy
              </v-btn>
              <span>We will process and print your orders with mapped products 24 hours after orders are placed</span>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <span class="page-title">{{ $t('Catalog') }}</span>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-center">
          <v-spacer></v-spacer>
          <v-btn :disabled="disabledCreate" color="primary" @click="createCampaign"
            ><i class="fas fa-plus mr-1"></i>{{ $t('Create new campaign') }}</v-btn
          >
        </v-col>
      </v-col>
      <!-- <v-col cols="12" class="d-flex subtitle-2">
        Select products for your campaign. You can choose up to 25 products per campaign.
      </v-col> -->
      <!-- <v-col cols="12" class="d-flex caption">Tips: More than 9 products affects the shopping experience</v-col> -->
      <v-spacer></v-spacer>
      <!-- <v-col cols="12" class="mt-4">
        <v-card class="mx-auto py-1" outlined>
          <v-card-text class="py-0">
            <v-col cols="12" class="caption font-weight-bold py-2 d-flex">
              Want more mockups? You can easily create your own using mockups provided in our library. It's totally
              free! Check out Mockup Library now!
              <v-btn outlined color="primary">
                Explore the Mockup Library
              </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col cols="12">
        <!-- <HeaderTableTabs v-if="!isLoading" :currentTab.sync="currentTab" :tabs="mockupType" /> -->
      </v-col>
      <v-col cols="12" v-if="!isLoading">
        <ListResource @disabledCreate="getDisable" ref="catalog" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ListResource from '../components/ListResouce.vue';
import { printHubApi } from '@/apis/printHub';
import { create } from 'handlebars';
export default {
  components: { ListResource },
  data() {
    return {
      disabledCreate: true,
      isLoading: false,
      currentTab: 0,
    };
  },
  computed: {
    ...mapGetters(['mockupType']),
  },
  methods: {
    getDisable(e) {
      this.disabledCreate = e;
    },
    createCampaign() {
      this.$refs.catalog.createCampaign();
      // this.$store.commit(`setListMockupSelected`, this.$refs.catalog.resource);
      // console.log(`this.$refs.catalog.resource`, this.$refs.catalog.document);
      // window.location.href = `/admin/app/print-hub/editor/mockupId`;
      // this.$router.push({ name: 'print-hub-editor', params: { mockupId: 'mockupId' } });
    },
  },
  async created() {
    this.isLoading = true;
    let res = await printHubApi.getMockupType();
    let types = res.data.mockupTypes.map(type => {
      return { name: type.name, key: type.name.toLowerCase() };
    });
    this.$store.commit('setMockupType', types);
    console.log('type', types);
    this.isLoading = false;
  },
};
</script>

<style></style>
